import React from "react";
import { AuthProvider } from "../context/auth-context";
import Layout from "../components/layout";
import Seo from "../components/seo";
import MyGamesWrapper from "../components/my-games-wrapper";
// markup
const MyGames = () => {
   
  return (
    <AuthProvider>
      <Layout>
        <Seo title={`Online Hangman`} /> 
        <MyGamesWrapper />
      </Layout>
    </AuthProvider>
  );
};

export default MyGames;

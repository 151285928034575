import React, { useState, useEffect } from "react";
import HeaderAd from "../components/ads/header-ad";
import { Link } from "gatsby";
import Loader from "react-loader-spinner";
import GamesList from "../components/games-list";
import GameService from "../services/game.service";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import { AuthContext } from "../context/auth-context";

// markup
const MyGamesWrapper = () => {
  const { user, setProfile } = React.useContext(AuthContext);
  const [games, setGames] = useState(null);
  const [loading, setLoading] = useState(null);
  const [page, setPage] = useState(1);
  const [status, setStatus] = useState(null);

  useEffect(() => {
    const pullSummary = async () => {
      //   setLoading(true);
      console.log("This part");
      const sum = await GameService.returnMySummary({ tally: true });
      console.log("Summary", sum);
      if (sum.error === null) {
        setStatus(sum.result);
        setProfile(sum.result);
      }
    };
    if (user !== null) {
      pullSummary();
    }
  }, []);

  useEffect(() => {
    const pullGamesEffect = async () => {
      setLoading(true);
      const myGames = await GameService.returnMyGames({ page });
      if (myGames.error === null) {
        setGames(myGames.result);
        setLoading(false);
      }
    };
    if (user !== null) {
      pullGamesEffect();
    }
  }, [page]);

  return (
    <>
      <div>
        <HeaderAd />
      </div>
      <div className={"container mx-auto  px-4 pb-6 py-6"}>
        <h2 className="text-2xl mb-5 text-center font-bold leading-7 text-gray-900 sm:text-3xl sm:truncate">
          My Games
        </h2>
        {status && (
          <p className={"mb-4 text-center"}>
            You have have {status.wins} wins and {status.loses} failed attempts
            out of {status.totalGames} games. Your average score is{" "}
            {Math.round(status.average)} per game. Total Score so far is{" "}
            {status.totalScore}.
          </p>
        )}

        {loading === true ? (
          <div className={"mx-auto"}>
            <Loader type="ThreeDots" color="#00BFFF" height={100} width={100} />
          </div>
        ) : (
          games !== null && <GamesList games={games} setPage={setPage} />
        )}

        <p className={"text-center mt-6 "}>
          <Link to={"/"}>Back to Category List</Link>
        </p>
      </div>
    </>
  );
};

export default MyGamesWrapper;

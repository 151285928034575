import React from 'react'
import moment from "moment";
import Pagination from "./pagination";

export default function GamesList({games, setPage}){

    return <div className="flex flex-col">
    <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
      <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
        <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
          <table className="min-w-full max-w-full divide-y divide-gray-200">
            <thead className="bg-gray-50">
              <tr>
                <th
                  scope="col"
                  className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                >
                  Word
                </th>
                <th
                  scope="col"
                  className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                >
                  Date
                </th>
                <th
                  scope="col"
                  className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                >
                  Category
                </th>
                <th
                  scope="col"
                  className=" normal px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                >
                  Score
                </th>
                <th
                  scope="col"
                  className=" normal px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                >
                  Time
                </th>
                <th
                  scope="col"
                  className="text-right relative px-6 py-3"
                >
                  Status
                </th>
              </tr>
            </thead>
            <tbody className="bg-white divide-y divide-gray-200">
              {games.docs &&
                games.docs.map((game, i) => {
                  return (
                    <tr key={i}>
                      <td className="px-6 py-4 whitespace-nowrap">
                        <div className="flex items-center">
                          <div className="ml-0">
                            <div className="text-sm font-medium text-gray-900">
                              {game.wordObject.word}
                            </div>
                          </div>
                        </div>
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap">
                        <div className="flex items-center">
                          <div className="ml-0">
                            <div className="text-sm font-medium text-gray-900">
                              {moment(game.createdAt).calendar()}
                            </div>
                          </div>
                        </div>
                      </td>
                      <td className="px-6 py-4 normal">
                        <div className="flex items-center">
                          <div className="w-full font-medium text-gray-900 text-left">
                            {game.category
                              ? game.category.name
                              : null}
                          </div>
                        </div>
                      </td>
                      <td className="px-6 py-4 normal">
                        <div className="flex items-center">
                          <div className="ml-0">
                            <div className="text-sm font-medium text-gray-900">
                              {game.score}
                            </div>
                          </div>
                        </div>
                      </td>
                      <td className="px-6 py-4 normal">
                        <div className="flex items-center">
                          <div className="ml-0">
                            <div className="text-sm font-medium text-gray-900">
                              {game.seconds !== 10000
                                ? game.seconds
                                : "Not Finished"}
                            </div>
                          </div>
                        </div>
                      </td>
                      <td className="px-6 py-4 normal">
                        <div className="flex items-center">
                          <div className="w-full font-medium text-gray-900 text-right">
                            {game.status === "won"
                              ? "WON!"
                              : "LOST"}
                          </div>
                        </div>
                      </td>
                    </tr>
                  );
                })}
            </tbody>
          </table>
          {games !== null && (
            <Pagination
              updatePage={(p) => {
                setPage(p);
              }}
              documents={games}
            />
          )}
        </div>
      </div>
    </div>
  </div>
}